import React, {useEffect, useState} from 'react';
import './TableBlock.css'
import {Empty, Form, Input, Modal, Table, Tooltip} from "antd";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import moment from 'moment'
import {lanGs} from "../utils/langData";
import {ReactComponent as ResultIcon} from '../assets/icon/lists.svg'
const TableBlock = ({data, lang}) => {
    const [tableItems, setTableItems] = useState(null)
    const [searchText, setSearchText] = useState(null);
    const [searchDepartments, setSearchDepartments] = useState(null);
    const [searchContractor, setSearchContractor] = useState(null);
    const [column, setColumn] = useState(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(null);

    useEffect(() => {
        setTableItems(data)
    }, [data, lang])
    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const searchValue = (item, column, lang) => {
        switch (column) {
            case'text':
                return searchText ? item?.filter(i => i?.text[lang]?.toLowerCase().includes(searchText?.toLowerCase())) : item
            case'departments':
                return searchDepartments ? item?.filter(i => i.departments.filter(f => f.shortName[lang].toLowerCase().includes(searchDepartments?.toLowerCase()))[0]) : item
            default :
                return item
        }
    }

    let dobleChild = (item) => {
        if (item === 'status' && data)
            return (
                data.map((i) => i[item]).filter((elem, index, self) => index === self.findIndex((e) => e.id === elem.id))
                    .map((i) => {
                        return {
                            text: i.name[lang],
                            value: i.id,
                        };
                    })
            );
        if (item === 'supervisor' && data) {
            return (data.map((i) => i[item])?.filter((elem, index, self) =>
                index === self.findIndex((e) => e?.id === elem?.id)).map((i) => {
                return i ?
                    {
                        text: i?.shortName,
                        value: i?.id,
                    } : {
                        text: lanGs['Не указан'][lang],
                        value: 0,
                    };
            }));
        }
    };
    const handleReset = (item) => {
        switch (item) {
            case 'text':
                return setSearchText(null)
            case 'departments':
                return setSearchText(null)
            default:
                return setSearchContractor(null)
        }
    };
    const [form] = Form.useForm();
    const onReset = () => form.resetFields()
    const getColumnSearchProps = (item) => {
        switch (item) {
            case 'text':
                return {
                    filterDropdown: () => (
                        <div style={{padding: 8,}} className={'modal_item_search'}>
                            <Form form={form}>
                                <Form.Item name="text">
                                    <Input
                                        className={'input_table_search'}
                                        placeholder={lanGs['Поиск по наименованию'][lang]}
                                        onChange={(e) => {
                                            setColumn('text')
                                            setSearchText(e.target.value ? e.target.value : null)
                                        }}
                                        allowClear
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    ),
                    filterIcon: () => (!searchText ?
                        <SearchOutlined/> :
                        <CloseOutlined style={{color: searchText ? '#1890ff' : undefined,}} onClick={() => {
                            onReset();
                            handleReset(item)
                        }}/>),
                }
            case 'departments':
                return {
                    filterDropdown: () => (
                        <div style={{padding: 8,}} className={'modal_item_search'}>
                            <Form form={form}>
                                <Form.Item name="departments">
                                    <Input
                                        className={'input_table_search'}
                                        placeholder={lanGs['Поиск по департаменту'][lang]}
                                        onChange={(e) => {
                                            setColumn('departments')
                                            setSearchDepartments(e.target.value ? e.target.value : null)
                                        }}
                                        allowClear
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    ),
                    filterIcon: () => (!searchText ?
                        <SearchOutlined/> :
                        <CloseOutlined style={{color: searchText ? '#1890ff' : undefined,}} onClick={() => {
                            onReset();
                            handleReset(item)
                        }}/>),
                }
            case 'executers':
                return {
                    filterDropdown: () => (
                        <div style={{padding: 8,}} className={'modal_item_search'}>
                            <Form form={form}>
                                <Form.Item name="executers">
                                    <Input
                                        className={'input_table_search'}
                                        placeholder={lanGs['Поиск по исполнителю'][lang]}
                                        onChange={(e) => {
                                            setColumn('executers')
                                            setSearchDepartments(e.target.value ? e.target.value : null)
                                        }}
                                        allowClear
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    ),
                    filterIcon: () => (!searchText ?
                        <SearchOutlined/> :
                        <CloseOutlined style={{color: searchText ? '#1890ff' : undefined,}} onClick={() => {
                            onReset();
                            handleReset(item)
                        }}/>),
                }
            default:
                return {
                    filterDropdown: () => (
                        <div style={{padding: 8,}} className={'modal_item_search'}>
                            <Form form={form}>
                                <Form.Item name="supervisor">
                                    <Input
                                        className={'input_table_search'}
                                        placeholder={lanGs['Поиск по куратору'][lang]}
                                        onChange={(e) => {
                                            setColumn('supervisor')
                                            setSearchContractor(e.target.value ? e.target.value : null)
                                        }}
                                        allowClear
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    ),
                    filterIcon: () => (
                        !searchContractor ?
                            <SearchOutlined/> :
                            <CloseOutlined style={{color: searchContractor ? '#1890ff' : undefined,}} onClick={() => {
                                onReset();
                                handleReset(item)
                            }}/>
                    ),
                }
        }
    }
    const columns = [
        {
            title: (<Tooltip title={lanGs['№ Проектного поручения'][lang]}><span className={"table_icon_head"}>{lanGs['№ пп'][lang]}</span></Tooltip>),
            dataIndex: `numberPP`,
            key: "numberPP",
            width: 30,
            sorter: {compare: (a, b) => a.numberPP - b.numberPP,},
            sortOrder: sortedInfo.columnKey === "numberPP" ? sortedInfo.order : null,

        },
        {
            title: (<Tooltip title={lanGs['Наименование'][lang]}><span className={"table_icon_head"}>{lanGs['Наименование'][lang]}</span></Tooltip>),
            dataIndex: 'text',
            render: (item, r) => <span className={`line_item first_item colorID`}>{item[lang]}</span>,
            key: 'text',
            className: 'numberPP_style',
            width: 350, // Минимальная ширина колонки
            // ellipsis: true, // Обрезание текста с многоточием
            // ellipsis: 'title', // Многоточие и всплывающая подсказка с полным текстом при наведении
        },
        {
            title: (<Tooltip title={lanGs['Куратор'][lang]}><span className={"table_icon_head"}>{lanGs['Куратор'][lang]}</span></Tooltip>),
            dataIndex: `supervisor`,
            render: (item, r) => {

                return <span title={item?.name}>{item?.shortName}</span>
            },
            key: "supervisor",
            width: 100,
            ellipsis: true,
            filters: dobleChild("supervisor"),
            onFilter: (value, record) => {
                return record.supervisor ? record.supervisor?.id === value : 0 === value
            },
        },
        {
            title: (<Tooltip title={lanGs['Ответственный за работы департамент'][lang]}><span
                className={"table_icon_head"}>{lanGs['Ответственный'][lang]}</span></Tooltip>),
            dataIndex: `departments`,
            render: (item, r) => {

                    return  item?.length > 0 ? item?.map((i, index) => {
                        return <span title={i?.name? i?.name[lang]: ''}>
                            {index > 0 ? ', ' + (i?.shortName && i?.shortName[lang]) : i?.shortName && i?.shortName[lang]}
                        </span>
                    }):  null
            },
            key: "departments",
            // ellipsis: true,
            width: 155,
            ...getColumnSearchProps('departments'),
            sorter: (a, b) => {
                return a?.departments[0]?.shortName[lang]?.localeCompare(b.departments[0]?.shortName[lang]);
            },
            sortOrder: sortedInfo.columnKey === "departments" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={lanGs['Срок'][lang]}><span className={"table_icon_head"}>{lanGs['Срок'][lang]}</span></Tooltip>),
            dataIndex: `endDate`,
            render: (item, r) => {
                return item ? item : '';
            },
            key: "endDate",
            width: 100,
            sorter: {compare: (a, b) => moment(a.endDate, "DD.MM.YYYY") - moment(b.endDate, "DD.MM.YYYY"),},
            sortOrder: sortedInfo.columnKey === "endDate" ? sortedInfo.order : null,
        },

/*------------*/

        {
            title: (<Tooltip title={lanGs['Ответственный за работы департамент'][lang]}><span
                className={"table_icon_head"}>{lanGs['Исполнитель'][lang]}</span></Tooltip>),
            dataIndex: `executers`,
            render: (item, r) => {

                return  item?.length > 0 ? item?.map((i, index) => {
                    return <span title={index > 0 ? ', ' + (i?.name && i?.name) : i?.name && i?.name}>
                            {index > 0 ? ', ' + (i?.shortName && i?.shortName) : i?.shortName && i?.shortName}
                        </span>
                }):  null
            },
            key: "executers",
            // ellipsis: true,
            width: 120,
            ...getColumnSearchProps('executers'),
            sorter: (a, b) => {
                return a?.executers[0]?.shortName?.localeCompare(b.executers[0]?.shortName);
            },
            sortOrder: sortedInfo.columnKey === "executers" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={lanGs['Срок'][lang]}><span className={"table_icon_head"}>{lanGs['Дата исполнения'][lang]}</span></Tooltip>),
            dataIndex: `factDate`,
            render: (item, r) => {
                return item ? item : '';
            },
            key: "factDate",
            width: 110,
            sorter: {compare: (a, b) => moment(a.factDate, "DD.MM.YYYY") - moment(b.factDate, "DD.MM.YYYY"),},
            sortOrder: sortedInfo.columnKey === "factDate" ? sortedInfo.order : null,
        },

/*----------*/

        {
            title: ' ',
            dataIndex: `result`,
            render: (item, r) => {
                return item ? <Tooltip title={lanGs['Результат исполнения'][lang]}><span className={'result_icon'} onClick={() => {
                    r?.result ? setIsModalOpen(r): setIsModalOpen(null)
                }}><ResultIcon /></span></Tooltip> : '';
            },
            key: "result",
            width: 40,
            // sorter: {compare: (a, b) => moment(a.factDate, "DD.MM.YYYY") - moment(b.factDate, "DD.MM.YYYY"),},
            // sortOrder: sortedInfo.columnKey === "factDate" ? sortedInfo.order : null,
        },

        {
            title: (<Tooltip title={lanGs['Статус работ'][lang]}><span className={"table_icon_head"}>{lanGs['Статус'][lang]}</span></Tooltip>),
            dataIndex: `status`,
            render: (item, r) => {

                return item ? (<span
                    className={'status_style_btn'}
                    style={{backgroundColor: item?.color ? item?.color : "",
                }}> {item.name[lang]} </span>) : (lanGs['Не' +
                ' указан'][lang]);
            },
            key: "status",
            width: 120,
            filters: dobleChild("status"),
            onFilter: (value, record) => {
                return record.status.id === value
            },
            sorter: (a, b) => {
                return a?.status?.name ? a?.status?.name[lang].localeCompare(b?.status?.name ? b?.status?.name[lang] : lanGs['Не указан'][lang]) : lanGs['Не указан'][lang];
            },
            sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
        },

    ];
    const handleOk = () => {
        setIsModalOpen(null);
    };
    const handleCancel = () => {
        setIsModalOpen(null);
    };
    return (
        <div className={'TableBlock_wrap'}>
            {tableItems ? <Table
                columns={columns}
                dataSource={searchValue(tableItems, column, lang)} className={'TableBlock_table'}

                scroll={{ x: 1200, y: '36vh' }} // Адаптивная высота прокрутки
                pagination={false}
                scrollToFirstRowOnChange={true}
                onChange={handleChange}
                bordered
                rowKey="id"
                size="small"
                width="100%"
                sticky={true}
            /> : <span className={'NO_DATA'}><Empty/></span>}
            <Modal title={lanGs['Результат исполнения'][lang]} open={isModalOpen} onOk={handleOk} centered onCancel={handleCancel} footer={false}>
               <div style={{ whiteSpace: 'pre-wrap' }}>
                   {isModalOpen?.result}
               </div>

            </Modal>
        </div>
    );
};
export default TableBlock;

