export const lanGs = {
  "Оставить отзыв": {
    nameRu: "Оставить отзыв",
    nameKz: "Пікір қалдыру",
  },
  "Панель руководителя": {
    nameRu: "Панель руководителя",
    nameKz: "Басқару тақтасы",
  },
  "Встречи акима с населением": {
    nameRu: "Встречи акима с населением",
    nameKz: "Әкімнің халықпен кездесуі",
  },
  "Центр развития города Алматы": {
    nameRu: "Центр развития города Алматы",
    nameKz: "Алматы қаласын дамыту орталығы",
  },
  "Акимат города Алматы": {
    nameRu: "Акимат  города Алматы",
    nameKz: "Алматы қаласының әкімдігі",
  },
  "Управление Цифровизации города Алматы": {
    nameRu: "Управление   Цифровизации города Алматы",
    nameKz: "Алматы қаласы Цифрландыру басқармасы",
  },
  'Вход': {
    nameRu: "Вход",
    nameKz: "Кіру",
  },
  "Главная страница": {
    nameRu: "Главная страница",
    nameKz: "Басты бет",
  },
  "Ситуационный центр": {
    nameRu: "Ситуационный центр",
    nameKz: "Ахуалдық орталық",
  },
  'Список встреч акима с жителями': {
    nameRu: "Список встреч акима с жителями",
    nameKz: "Әкімнің тұрғындармен кездесулерінің тізімі",
  },
  'Дата встречи': {
    nameRu: "Дата встречи",
    nameKz: "Кездесу күні",
  },
  'Протокол': {
    nameRu: "Протокол",
    nameKz: "Хаттама",
  },
  'Смотреть': {
    nameRu: "Смотреть",
    nameKz: "Қарау",
  },
  'Поручения по итогам встречи акима с населением': {
    nameRu: "Поручения по итогам встречи акима с населением",
    nameKz: "Әкімнің халықпен кездесу қорытындысы бойынша тапсырмалар",
  },
  'Список встреч акима с жителями Алматы': {
    nameRu: "Список встреч акима с жителями Алматы",
    nameKz: "Әкімнің Алматы тұрғындарымен кездесулерінің тізімі",
  },
  'Обращения, по которым даны разъяснения в ходе встречи': {
    nameRu: "Обращения, по которым даны разъяснения в ходе встречи",
    nameKz: "Кездесу барысында түсініктемелер берілген өтініштер",
  },
  "Не указан": {
    nameRu: "Не указан",
    nameKz: "Көрсетілмеген",
  },
  "Поиск по наименованию": {
    nameRu: "Поиск по наименованию",
    nameKz: "Атауы бойынша іздеу",
  },
  "Поиск по департаменту": {
    nameRu: "Поиск по департаменту",
    nameKz: "Департамент бойынша іздеу",
  },
  "Поиск по куратору": {
    nameRu: "Поиск по куратору",
    nameKz: "Куратор бойынша іздеу",
  },
  "Наименование": {
    nameRu: "Наименование",
    nameKz: "Атауы",
  },
  "Ответственный": {
    nameRu: "Ответственный",
    nameKz: "Жауапты",
  },
  'Срок': {
    nameRu: "Срок",
    nameKz: "Мерзімі",
  },
  'Статус': {
    nameRu: "Статус",
    nameKz: "Мәртебесі",
  },
  "Выбор района": {
    nameRu: "Выбор района",
    nameKz: "Аудан таңдау",
  },
  "№ Проектного поручения": {
    nameRu: "№ Проектного поручения",
    nameKz: "Жобалық тапсырманың №",
  },
  'Куратор': {
    nameRu: "Куратор",
    nameKz: "Куратор",
  },
  'Аналитика поручений': {
    nameRu: "Аналитика поручений",
    nameKz: "Тапсырмаларды талдау",
  },
  'Просмотреть аналитику': {
    nameRu: "Просмотреть аналитику",
    nameKz: "Аналитиканы қарау",
  },
  'Ответственный за работы департамент': {
    nameRu: "Ответственный за работы департамент",
    nameKz: "Жұмыстарға жауапты департамент",
  },
  'Статус работ': {
    nameRu: "Статус работ",
    nameKz: "Жұмыс мәртебесі",
  },
  '№ пп': {
    nameRu: "№ пп",
    nameKz: "жт №",
  },
  'Лого ситуационного центра города Алматы': {
    nameRu: "Лого ситуационного центра города Алматы",
    nameKz: "Алматы қаласының ахуалдық орталығының логосы",
  },
  'Ситуационный центр города Алматы': {
    nameRu: "Ситуационный центр города Алматы",
    nameKz: "Алматы қаласының Ахуалдық орталығы",
  },
    'Количество исполненных поручений': {
  nameRu: "Количество исполненных поручений",
      nameKz: "Орындалған тапсырмалар саны",
},
  'Общее количество поручений акима по итогам встречи': {
    nameRu: "Общее количество поручений акима по итогам встречи",
    nameKz: "Кездесу қорытындысы бойынша әкім тапсырмаларының жалпы саны",
  },
  'Протокол встречи акима с населением, для просмотра': {
    nameRu: "Протокол встречи акима с населением, для просмотра",
    nameKz: "Әкімнің халықпен кездесу хаттамасы, көру үшін",
  },
  'Видеозапись встречи акима с населением, для просмотра': {
    nameRu: "Видеозапись встречи акима с населением, для просмотра",
    nameKz: "Әкімнің халықпен кездесуінің бейнежазбасы, көру үшін",
  },
  'Видео временно не доступно': {
    nameRu: "Видео временно не доступно",
    nameKz: "Бейне уақытша қол жетімді емес",
  },
  'Поручения по итогам встречи акима с жителями г. Алматы': {
    nameRu: "Поручения по итогам встречи акима с жителями г. Алматы",
    nameKz: "Әкімнің Алматы қаласының тұрғындарымен кездесу қорытындысы бойынша тапсырмалар",
  },
  'Исполнитель': {
    nameRu: "Исполнитель",
    nameKz: "Орындаушы",
  },
  'Дата исполнения': {
    nameRu: "Дата исполнения",
    nameKz: "Орындалу күні",
  },
  'Поиск по исполнителю': {
    nameRu: "Поиск по исполнителю",
    nameKz: "Орындаушы бойынша іздеу",
  },
  'Результат исполнения': {
    nameRu: "Результат исполнения",
    nameKz: "Орындау нәтижесі",
  },
  'Обращения жителей': {
    nameRu: "Обращения жителей",
    nameKz: "Тұрғындардың өтініштері",
  },'Протокольные поручения акима': {
    nameRu: "Протокольные поручения акима",
    nameKz: "Әкімнің хаттамалық тапсырмалары",
  },
};
