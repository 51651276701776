import React, {useEffect, useState} from 'react';
import './MetUi.css'
import HeaderBlock from "../common/HeaderBlock";
import DistrictNav from "../common/DistrictNav";
import BodyBlock from "../common/BodyBlock";
import axios from "axios";
import {ConfigProvider} from "antd";
import 'dayjs/locale/zh-cn';
import rRu from 'antd/locale/ru_RU';
import rKz from 'antd/locale/kk_KZ';
import '../utils/media.css'


const MetUi = () => {
    const [lang, setLang] = useState("nameRu");
    const [meetings, setMeetings] = useState(null);
    const [meetingId, setMeetingId] = useState(null);
    const [metListPP, setMetListPP] = useState(null);
    const [metListQ, setMetListQ] = useState(null);
    const [distChoice, setDistChoice] = useState({id: 0, nameKz: "Алматы қ.", nameRu: "г. Алматы"});

    useEffect(() => {
        setMetListPP(null)
        setMetListQ(null)
        setMeetingId(null)
        axios({
            method: 'get',
            url: `/api/v1/meetings?distId=${distChoice.id === 0? '' : distChoice.id}`,
            headers: {"Content-type": "application/json; charset=UTF-8"},
        }).then(res => {
            setMeetings(res.data.length > 0 ? res.data : null);
            setMeetingId(res.data.length > 0 ? res.data[0] : null)
        })
            .catch(function (error) {
            });
    }, [distChoice])

    useEffect(() => {

        meetingId && axios({

            method: 'get',

           url: `/api/v1/protocols?meetingId=${meetingId?.id}`,
            headers: {"Content-type": "application/json; charset=UTF-8"},
        }).then(res => {
            setMetListPP(null);
            setMetListPP(res.data)
        })
            .catch(function (error) {
            });

        meetingId?.id && axios({
            method: 'get', url: `/api/v1/questions?meetingId=${meetingId?.id}`, headers: {"Content-type": "application/json; charset=UTF-8"},
        }).then(res => {
            setMetListQ(null);
            setMetListQ(res.data)
        })
            .catch(function (error) {
            });

    }, [meetingId, meetingId?.id])


    return (
        <div className={'MetUi_wrapper'}>
            <ConfigProvider locale={lang !== 'nameRu' ? rKz : rRu}>
                <div className={'MetUi_wrap'}>
                    <div className={'MetUi_header'}>
                        <HeaderBlock
                            setLang={setLang}
                            lang={lang}
                        />
                    </div>
                    <div className={'MetUi_nav'}>
                        <DistrictNav
                            lang={lang}
                            distChoice={distChoice}
                            setDistChoice={setDistChoice}
                        />
                    </div>
                    <div className={'MetUi_body'}>
                        <BodyBlock
                            lang={lang}
                            distChoice={distChoice}
                            meetings={meetings}
                            setMeetingId={setMeetingId}
                            meetingId={meetingId}
                            metListPP={metListPP}
                            metListQ={metListQ}
                        />
                    </div>
                </div>
            </ConfigProvider>
        </div>
    );
};

export default MetUi;
