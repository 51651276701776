import React, {useEffect, useState} from 'react';
import './TableBlock.css'
import {lanGs} from "../utils/langData";
import {Empty, Form, Input, Table, Tooltip} from "antd";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";
import moment from "moment/moment";
const SmallTable = ({data, lang, setTableItemsClick, setIsEdit, }) => {
    const [tableItems, setTableItems] = useState(null)
    const [searchText, setSearchText] = useState(null);
    const [searchDepartments, setSearchDepartments] = useState(null);
    const [searchContractor, setSearchContractor] = useState(null);

    const [column, setColumn] = useState(null);

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    useEffect(() => {
        setTableItems(data)
    }, [data, lang])
    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const searchValue = (item, column, lang) => {
        switch (column) {
            case'text':
                return searchText ? item?.filter(i => i?.text[lang]?.toLowerCase().includes(searchText?.toLowerCase())) : item
            default :
                return item
        }
    }

    const handleReset = (item) => {
        switch (item) {
            case 'text':
                return setSearchText(null)
            default:
                return setSearchContractor(null)
        }

    };
    const [form] = Form.useForm();
    const onReset = () => {
        form.resetFields();
    }
    const getColumnSearchProps = (item) => {
        switch (item) {
            case 'text':
                return {
                    filterDropdown: () => (
                        <div style={{padding: 8,}} className={'modal_item_search'}>
                            <Form form={form}>
                                <Form.Item name="text">
                                    <Input
                                        className={'input_table_search'}
                                        placeholder={lanGs['Поиск по наименованию'][lang]}
                                        // value={selectedKeys[0]}
                                        onChange={(e) => {
                                            setColumn('text')
                                            setSearchText(e.target.value ? e.target.value : null)
                                        }}
                                        allowClear
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    ),
                    filterIcon: () => (!searchText ?
                        <SearchOutlined/> :
                        <CloseOutlined style={{color: searchText ? '#1890ff' : undefined,}} onClick={() => {
                            onReset();
                            handleReset(item)
                        }}/>),
                }
            default: break
        }
    }
    const columns = [
        {
            title: (<Tooltip title={'№'}>
                    <span className={"table_icon_head"}>№</span>
                </Tooltip>),
            dataIndex: `numberPP`,
            render: (item, r, index) => index + 1,
            key: "numberPP",
            width: "70px",
            // sorter: {compare: (a, b) => a.index + 1 - b.index + 1,},
            sortOrder: sortedInfo.columnKey === "numberPP" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={lanGs['Наименование'][lang]}>
                    <span className={"table_icon_head"}>{lanGs['Наименование'][lang]}</span>
                </Tooltip>),
            dataIndex: `text`,
            render: (item, r) => <span className={`line_item first_item colorID}`}>{item[lang]}</span>,
            key: "text",
            ...getColumnSearchProps('text'),
        },
    ];

    return (
        <div className={'TableBlock_wrap'}>
            {tableItems ? <Table
                columns={columns}
                dataSource={searchValue(tableItems, column, lang)} className={'TableBlock_table'}
                scroll={{ x: 'auto', y: '22vh' }} // Адаптивная высота прокрутки
                pagination={false}

                scrollToFirstRowOnChange={true}
                onChange={handleChange}
                bordered
                rowKey="id"
                size="small"
            />: <span className={'NO_DATA'}><Empty /></span>}
        </div>
    );
};

export default SmallTable;
